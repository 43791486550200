import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { useForm } from "react-hook-form";
import { sendPushNotification } from "../common/slices/user";
import theme from '../common/theme';
import { ButtonBase, RedButton } from "./Buttons";
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Modal from './Modal';
import { H1, P } from "./Typography";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border}
`

const ModalSendPushNotification = ({ userId, onClose }) => {
    const dispatch = useDispatch();

    const onSaveClick = async (values) => {
        await dispatch(sendPushNotification(userId, values.title, values.body));
        onClose();
    }

    const {
        control,
        setError,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
    } = useForm({})
    const fieldProps = { control, errors, rules: { required: true } };

    return (
        <Modal
            show
            onClose={onClose}
            size='sm'
        >
            <Container>
                <Header>
                    <H1>Send Push Notification</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'xl'} /></ButtonBase>
                </Header>
                <P>Use the form below to send a real-time push notification to this user.</P>
                <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                    <FormTextInput {...fieldProps} name="title" placeholder="Enter a title" label="Title" />
                    <FormTextArea {...fieldProps} name="body" placeholder="Enter a message" label="Message" />
                </form>
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Send Message</RedButton>
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalSendPushNotification