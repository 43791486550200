import { API } from "aws-amplify";
import { requestLocation } from "../graphql/mutations";

export default async (userId) => {
    try {
        const { data } = await API.graphql({
            query: requestLocation,
            variables: {
                input: {
                    userId
                }
            }
        })
        return data.requestLocation
    } catch (ex) {
        console.log("failed to request location", ex);
    }
};
