import { useEffect, useRef } from 'react';
import { useState } from 'react';
import isOnJourney from '../common/utils/isOnJourney';
import isOnShift from '../common/utils/isOnShift';
import ProgressBar from './Progress';
import moment, { duration } from 'moment';
import getUpdateDelay from '../service/getUpdateDelay'
import { P2 } from './Typography';


const LastUpdate = ({ checkIn }) => {
    const [state, setState] = useState({ progress: 0, alarm: false, fromNow: '', updatedAt: null })
    const timeoutRef = useRef();

    const updateState = () => {
        let delay = getUpdateDelay(checkIn);
        const fromNow = moment(checkIn?.timestamp).fromNow();
        const expected = moment(checkIn?.timestamp).add(delay, 'second');
        const diff = expected.diff(moment(), 'second');
        const progress = (1 - (diff / delay)) * 100;
        const alarm = diff < 1;
        setState({
            updatedAt: moment(checkIn?.timestamp),
            fromNow,
            progress,
            alarm
        })
    }

    useEffect(() => {
        timeoutRef.current = setInterval(updateState, 1000);
        return () => clearInterval(timeoutRef.current);
    }, [checkIn?.updatedAt])

    return (
        <div>
            <P2 style={{ color: 'rgba(187, 187, 187, 1)', margin: 0, padding: 0 }}>Last Updated</P2>
            {(isOnShift(checkIn?.status) || isOnJourney(checkIn?.status)) && <ProgressBar progress={state.progress} alarm={state.alarm} />}
            {state.updatedAt?.format("h:mm a")} - {state.fromNow}
        </div>
    )
}

export default LastUpdate;