import {
    Circle, Polygon
} from "@react-google-maps/api";
import moment from 'moment';
import { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import theme from "../common/theme";
import isOnJourney from "../common/utils/isOnJourney";
import isOnShift from "../common/utils/isOnShift";
import { selectArchive } from "../slices/archive";
import Map from "./Map";
import CheckInMarker from "./MarkerCheckIn";
import PointsOfInterest from "./PointsOfInterest";
import PolylineArchive from "./PolylineArchive";

const AccuracyRadius = ({ checkIn }) => {
    const [radius, setRadius] = useState(0);
    let { location: { lat, lng, accuracy } } = checkIn

    const updateRadius = () => {
        setRadius(accuracy);
    }

    useEffect(() => {
        updateRadius();
    }, [checkIn])

    if (!isOnShift(checkIn.status) && !isOnJourney(checkIn.status)) {
        return null;
    }

    return (
        <Circle center={{ lat, lng }} radius={radius} options={{
            strokeColor: theme.palette.border,
            strokeWeight: 2,
            fillColor: theme.palette.border,
            fillOpacity: 0.15,
            strokeOpacity: 0.2,
        }} />
    )
}

function calculatePieCoordinates(origin, heading, distance, angle = 30) {
    if (!window.google?.maps?.geometry?.spherical?.computeOffset) {
        return []
    }

    // convert the origin point from lat-long to web mercator
    const originWebMercator = window.google.maps.geometry.spherical.computeOffset(origin, 0, heading);
    let points = [originWebMercator];  // array to store the points of the pie

    // convert heading and angle to radians
    const headingRadians = heading * (Math.PI / 180);
    const angleRadians = angle * (Math.PI / 180);
    for (let i = headingRadians - angleRadians / 2; i < headingRadians + angleRadians / 2; i += 0.01) {
        // calculate x and y using trigonometry
        const latlng = window.google.maps.geometry.spherical.computeOffset(originWebMercator, distance, i * 180 / Math.PI);
        points.push(latlng);
    }
    points.push(originWebMercator);
    return points;
}


const ProbabilityPolygon = ({ checkIn, angle = 45 }) => {
    const [state, setState] = useState({ coordinates: [] });
    const timeoutRef = useRef();
    let { location: { lat, lng, heading, speed }, updatedAt, timestamp } = checkIn

    const updateRadius = () => {
        if (speed < 1) { speed = 0.25 }
        const distance = speed * moment().diff(timestamp, 's', true);
        const coordinates = calculatePieCoordinates({ lat, lng }, heading, distance * 1, angle)
        const coordinates2 = calculatePieCoordinates({ lat, lng }, heading, distance * 1.25, angle * 1.5)
        setState({
            coordinates,
            coordinates2
        })
        timeoutRef.current = requestAnimationFrame(updateRadius);
    }

    useEffect(() => {
        updateRadius();
        return () => cancelAnimationFrame(timeoutRef.current);
    }, [checkIn])

    if (!isOnShift(checkIn.status) && !isOnJourney(checkIn.status)) {
        return null;
    }

    return (
        <>
            <Polygon path={state.coordinates2} options={{
                strokeColor: theme.palette.green,
                fillColor: 'transparent',
                strokeWeight: 2,
            }} />
            <Polygon path={state.coordinates} options={{
                strokeColor: theme.palette.green,
                fillColor: theme.palette.green,
                fillOpacity: 0.5,
                strokeWeight: 1,
            }} />
        </>
    )
}


const MapActiveCheckIn = forwardRef(({ checkIn }, ref) => {
    const [center, setCenter] = useState(checkIn.location);
    const archive = useSelector(selectArchive);

    return (
        <Map
            mapContainerStyle={{
                height: '100%',
            }}
            ref={ref}
            zoom={18}
            center={center}
            options={{
                disableDefaultUI: true,
                mapTypeId: 'hybrid', //userDetailsMapType?.id || 'roadmap'
            }}
        >
            {/* <ProbabilityRadius checkIn={checkIn} key="probabilityRadius" /> */}
            {
                !archive.length ? [
                    <CheckInMarker key='checkInMarker' checkIn={checkIn} />,
                    <ProbabilityPolygon checkIn={checkIn} key="probabilityPolygon" />,
                    <AccuracyRadius checkIn={checkIn} key="probabilityRadius" />
                ] : [
                    <CheckInMarker key='checkInMarker' checkIn={checkIn} />,
                    <PolylineArchive key='polylineArchive' archive={archive} />,
                    <PointsOfInterest key="pointsOfInterest" archive={archive} />
                ]
            }
            {/* <TrafficLayer key="traffic" /> */}
        </Map>
    )
});

export default MapActiveCheckIn;