import { createSelector, createSlice } from "@reduxjs/toolkit";
import service from "../common/service";
import { reset } from "../common/slices/globalActions";

const initialState = {
    archive: [],
    date: null,
};

const archiveSlice = createSlice({
    name: "archive",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, (state) => {
            return initialState;
        });
    },
    reducers: {
        setArchive: (state, action) => {
            state.archive = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        clearArchive: () => {
            return initialState;
        }
    },
});

export const { clearArchive, setArchive, setDate } = archiveSlice.actions;
export default archiveSlice.reducer;

export const selectState = (state) => state.archive;
export const selectArchive = createSelector(selectState, (state) => state.archive);
export const selectArchiveDate = createSelector(selectState, (state) => state.date);

export const listArchive = (email, date, precision) => async (dispatch, getState) => {
    const archive = await service.listArchive(email, date, precision);
    dispatch(setArchive(archive));
    dispatch(setDate(date));
    return archive;
};
