import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Pmb, P, H3 } from "./Typography";
import { forwardRef } from "react";
import { ErrorMessage } from '@hookform/error-message';
import theme from "../common/theme";

const Input = styled.textarea`
    flex: 1;
    outline: none;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid rgb(204, 204, 204);
    font-family: "SF Pro Display", sans-serif;;    

    &::placeholder {
        font-family: "SF Pro Display", sans-serif;
        color: ${theme.palette.dark};
    }
`

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const UncontrolledFormTextAreaInput = forwardRef(({ errors, label, containerStyle, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            <H3 noMargin>{label}</H3>
            <Input
                ref={ref}
                {...rest}
            />
            {errors && <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P style={{ color: 'red' }}>{message}</P>}
            />}
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledFormTextAreaInput {...fieldProps} {...rest} />
            )}
        />
    );
};
