import { useForm } from "react-hook-form"
import FormCheckbox from "./FormCheckbox"
import styled from "styled-components"
import Divider from "./Divider"
import { BlueButton } from "./Buttons"
import { H1 } from "./Typography"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Container } from "./SelfRegistrationLayout"
import { useDispatch, useSelector } from "react-redux"
import { selectStatus, updateStatus } from "../common/slices/registration"

export default () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const status = useSelector(selectStatus);
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting }
    } = useForm({
        values: {
            accepted: status?.termsOfService?.done,
            trialLength: searchParams.get("trial_length"),
            referrer: searchParams.get("referrer")
        }
    })

    const fieldProps = { control, errors, rules: { required: true } }

    const onNext = (values) => {
        dispatch(updateStatus({ step: 'termsOfService', status: { done: true, status: 'ACCEPTED', acceptedAt: new Date().toISOString(), trialLength: values.trialLength } }))
        nav("/auth/registration/account-owner")
    }

    return (
        <Container>
            <H1>Terms of Service</H1>
            <iframe src={'https://besafeavatars170609-pre.s3.ca-central-1.amazonaws.com/public/software-agreement.html'} style={{ flex: 1, border: 'none' }} />
            <FormCheckbox name="accepted" placeholder="I have read and accept the terms of service" {...fieldProps} containerStyle={{ flex: 0 }} />
            <Divider />
            <BlueButton disabled={!isValid || isSubmitting} onClick={handleSubmit(onNext)}>Accept & Next</BlueButton>
        </Container>
    )
}
