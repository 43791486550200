import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { getCheckIns, subscribeCheckIns, unsubscribeCheckIns } from '../common/slices/checkIn';
import { selectUser } from "../common/slices/user";
import SideBarNotifications from "./SideBarNotifications";
import TopNav from "./TopNav";
import { P2 } from './Typography';
import p from '../../package.json';

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`

export const Foot = styled.footer`
    background-color: rgba(16, 18, 36, 1);
    text-align: center;
    padding: 0.5rem 0;
    border-top: 1px solid rgba(41, 44, 84, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    p:nth-child(even) {
        &::before {
            padding: 1rem;
            content: "|";
        }
        &::after {
            padding: 1rem;
            content: "|";
        }
    }
    
    p:nth-child(even):last-child {
        &::after { 
            content: ""; 
        }
    }
`

export const Footer = () => (
    <Foot>
        <P2>&copy; {new Date().getFullYear()} Be Safe - Work Alone Monitoring</P2>
        <P2><a href="/privacy">Privacy Policy</a></P2>
        <P2><a href="https://besafeavatars170609-pre.s3.ca-central-1.amazonaws.com/public/terms-of-use.html" target='_blank'>Terms of Use</a></P2>
        <P2>desktop version {p.version}</P2>
        <P2>mobile version 1.3.3</P2>
    </Foot>
)

const Layout = ({ signOut, children }) => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(subscribeCheckIns(user.companyId, user.role))
        dispatch(getCheckIns(user.companyId, user.role))
        return () => dispatch(unsubscribeCheckIns)
    }, [dispatch, user.companyId, user.role])

    return (
        <>
            <TopNav user={user} signOut={signOut} />
            <Content>
                {children}
            </Content>
            <Footer />
            <SideBarNotifications user={user} />
        </>
    );
};

export default Layout;
