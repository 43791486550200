import { API } from "aws-amplify";
import { listCheckInArchives } from "../graphql/customQueries";
import { getSmoothArchive } from "../graphql/queries";
import moment from 'moment';

export default async (email, date, precision = 0.5) => {
    try {
        const {
            data: {
                getSmoothArchive: archives,
            },
        } = await API.graphql({
            query: getSmoothArchive,
            variables: {
                input: {
                    email,
                    startDate: moment(date).startOf("day"),
                    endDate: moment(date).endOf("day"),
                    tolerance: 0.000001,
                    window: precision,
                }
            },
        });
        return archives;
    } catch (ex) {
        console.warn("list archives failed", ex);
    }
};
